import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from './article-preview.module.css'
import * as heroStyles from '../components/hero.module.css'

export default ({ article, locale }) => {
  const image = getImage(article.heroImage)

  return (
  <div>
    <Link to={`/${locale}/blog/${article.slug}`}>
        <GatsbyImage alt={article.title} image={image} className={heroStyles.heroImage} />
        <h3 className={styles.previewTitle}>
            {article.title}
        </h3>
    </Link>
    <small>{article.publishDate}</small>
    <div
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
    {article.tags && article.tags.map(tag => (
      <p className={styles.tag} key={tag}>
        {tag}
      </p>
    ))}
  </div>
  )
}
