import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import './blog.module.css'
import Layout from "../components/layout"
import ArticlePreview from '../components/article-preview'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const locale = "en"

    return (
      <Layout location={this.props.location} locale={locale}>
        <Helmet title={siteTitle} />
        {/* <div className={styles.hero}>
            Blog
          </div> */}
        <div className="wrapper">
          <h2 className="section-headline">Recent articles</h2>
          <ul className="article-list">
            {!!posts && posts !== undefined && posts.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} locale={locale} />
                </li>
              )
            })}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query EnBlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(filter: { node_locale: {eq: "en"} }, sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          node_locale
          tags
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
